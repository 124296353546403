import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const QueryComponent = () => {
  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isChatHiding, setIsChatHiding] = useState(false);
  const chatWindowRef = useRef(null);
  const textareaRef = useRef(null);
  const [sessionId, setSessionId] = useState("");

  const [selectedType, setSelectedType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [billNumber, setBillNumber] = useState("");

  const [displayedBills, setBills] = useState([]);
  const [animatedBills, setAnimatedBills] = useState([]);

  useEffect(() => {
    const id = uuidv4();
    setSessionId(id);
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      fetch(
        `https://waffle-purple-crayon-90210-ed54cb8ffdd9.herokuapp.com/delete_chatbot_instance?sessionId=${sessionId}`,
        {
          method: "DELETE",
        }
      ).catch((err) => console.error("Error deleting chatbot instance:", err));
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [sessionId]);

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current);
    }
  }, [query]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatMessage = (text) => {
    return text.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const handleQuery = () => {
    const userMessage = { text: query, isUser: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setQuery("");

    const eventSource = new EventSource(`https://waffle-purple-crayon-90210-ed54cb8ffdd9.herokuapp.com/query?query=${encodeURIComponent(query)}&sessionId=${sessionId}`);
    eventSource.onmessage = (event) => {
      console.log(event.data);
      const chunk = event.data.trim();
      console.log("Received chunk:", chunk);
      if (chunk) {
        setMessages(prevMessages => {
          const lastMessage = prevMessages[prevMessages.length - 1];

          if (lastMessage && !lastMessage.isUser) {
            return prevMessages.slice(0, -1).concat({
              text: lastMessage.text + " " + chunk,
              isUser: false,
            });
          } else {
            return [...prevMessages, { text: chunk, isUser: false }];
          }
        });
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource error:", error);
      eventSource.close();
    };

    eventSource.addEventListener("end", () => {
      eventSource.close();
    });
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    autoResizeTextarea(e.target);
  };

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const toggleChat = () => {
    if (isChatVisible) {
      setIsChatHiding(true);
      setTimeout(() => {
        setIsChatVisible(false);
        setIsChatHiding(false);
      }, 300);
    } else {
      setIsChatVisible(true);
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    const data = {
      billNumber: billNumber,
      year: selectedYear,
      type: selectedType

    };
    axios.post(`https://waffle-purple-crayon-90210-ed54cb8ffdd9.herokuapp.com/bill_info?sessionId=${sessionId}`, data)
      .then(response => {
        const bills = response.data.bills;
        setBills(bills);  // Set the list of bills returned from the backend
        setAnimatedBills(bills);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  // const handleBillClick = (billURL) => {
  // Action when the Load button is clicked
  // };*/
  const handleBillClick = (billURL) => {
    axios.post(`https://waffle-purple-crayon-90210-ed54cb8ffdd9.herokuapp.com/load_bill`, {
      bill_url: billURL,
      sessionId: sessionId,
    })
      .then((response) => {
        console.log("Bill loaded:", response.data);
        alert(`You clicked on bill: ${billURL}`);
        // Optionally, handle response data here (e.g., update UI with new information)
      })
      .catch((error) => {
        console.error("Error loading bill:", error);
      });
  };
  useEffect(() => {
    const timer = setTimeout(() => setAnimatedBills([]), 1000); // Adjust delay as needed
    return () => clearTimeout(timer);
  }, [displayedBills]);

  return (
    <>
      <button className="toggle-button" onClick={toggleChat}>
        <b>Click Here to Chat!</b>
      </button>
      <div className="main-wrapper">
        {(isChatVisible || isChatHiding) && (
          <div
            className={`container ${isChatVisible ? "show" : isChatHiding ? "hide" : ""
              }`}
          >
            <h1>Chat with GovBot</h1>
            <div className="chat-window" ref={chatWindowRef}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${message.isUser ? "user-message" : "bot-response"
                    }`}
                >
                  {/* Use ReactMarkdown with `breaks` prop to handle line breaks */}
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {message.text}
                  </ReactMarkdown>
                </div>
              ))}
            </div>
            <textarea
              value={query}
              ref={textareaRef}
              onChange={handleInputChange}
              placeholder="What would you like to learn?"
            />
            <button className="submit-button" onClick={handleQuery}>
              Ask
            </button>
          </div>
        )}

        <div className="side-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="bill-type">Select Chamber:</label>
              <select
                id="bill-type"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">--Select--</option>
                <option value="HB">HB</option>
                <option value="SB">SB</option>
                <option value="SR">SR</option>
                <option value="HR">HR</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="year">Select Year:</label>
              <select
                id="year"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="">--Select--</option>
                {Array.from({ length: 2024 - 2016 + 1 }, (_, i) => (
                  <option key={i} value={2016 + i}>
                    {2016 + i}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="bill-query">Enter a Topic:</label>
              <input
                type="text"
                id="bill-number"
                value={billNumber}
                onChange={(e) => setBillNumber(e.target.value)}
                maxLength={15}
                placeholder="Education..."
              />
            </div>

            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>

        <div className="bill-list-container">
          <h2>Bills:</h2>
          <div className="bill-window">
            {displayedBills.map((bill, index) => (
              <div
                key={bill.bill_number + index} // Use a unique key for each bill
                className={`bill ${animatedBills.includes(bill) ? "fly-in" : ""
                  }`} // Apply fly-in class conditionally
              >
                <div className="bill-number">{bill.bill_number}</div>
                <div className="bill-title">{bill.title}</div>
                <button onClick={() => handleBillClick(bill.bill_url)}>
                  Load
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryComponent;
